<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <h3 class="page-title mb-7 font-weight-bold">床材一覧</h3>
      <v-row class="no-gutters mb-6" align="center">
        <v-col class="text-right">
          <v-btn
            height="30"
            class="btn-new"
            color="#CFD3FE"
            depressed
            min-width="100"
            to="/management/floor-material/register"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            床材の登録
          </v-btn>
        </v-col>
      </v-row>
      <Table
        :headers="headers"
        :items="floorMaterials"
        :total-records="pagination.records_total"
        :number-of-pages="pagination.total_pages"
        :footer="false"
        class="pb-3"
        :loading="loading"
        :options.sync="options"
        :items-per-page="50"
        @click:row="edit"
      >
        <template v-slot:item.id="{ item }">
          <span>{{ item.id }}</span>
        </template>
        <template v-slot:item.type="{ item }">
          <span>
            {{ floorMaterialTypes[item.type] }}
          </span>
        </template>
        <template v-slot:item.color_code="{ item }">
          <v-btn
            v-if="item.type === 0"
            :color="item.color_code"
            :ripple="false"
            min-height="65"
            min-width="100px"
            class="floor-color"
          ></v-btn>
        </template>
        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.type === 1"
            :src="item.image_url"
            :aspect-ratio="1"
            max-width="100px"
            max-height="65px"
            class="mx-auto floor-img"
          ></v-img>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary mr-2"
            fab
            x-small
            :to="{
              name: 'FloorMaterialManagementEdit',
              params: {
                id: item.id
              }
            }"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { debounce } from 'lodash'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      headers: [
        { text: 'ID', align: 'center', value: 'id' },
        {
          text: '床材名',
          align: 'center',
          sortable: false,
          value: 'name'
        },
        {
          text: 'データタイプ',
          align: 'center',
          value: 'type',
          sortable: false
        },
        {
          text: '色コード',
          align: 'center',
          value: 'color_code',
          sortable: false
        },
        {
          text: '画像',
          align: 'center',
          value: 'image',
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      loading: false,
      search: '',
      options: {},
      floorMaterialTypes: ['色選択', '画像']
    }
  },
  computed: {
    floorMaterials() {
      return this.$store.getters.allFloorMaterials
    },
    pagination() {
      return this.$store.getters.floorMaterialPagination
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },

  methods: {
    async getDataFromApi() {
      this.loading = true
      let params = {
        search: encodeURI(this.search),
        paginate: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      }
      await this.$store.dispatch('FLOOR_MATERIAL_GET_ALL', params)
      this.loading = false
    },

    triggerSearch: debounce(function() {
      this.getDataFromApi()
    }, 1000),

    edit(param1, param2) {
      this.$router.push({
        name: 'FloorMaterialManagementEdit',
        params: {
          id: param2.item.id
        }
      })
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
