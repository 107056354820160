var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('h3', {
    staticClass: "page-title mb-7 font-weight-bold"
  }, [_vm._v("床材一覧")]), _c('v-row', {
    staticClass: "no-gutters mb-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "btn-new",
    attrs: {
      "height": "30",
      "color": "#CFD3FE",
      "depressed": "",
      "min-width": "100",
      "to": "/management/floor-material/register"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" 床材の登録 ")], 1)], 1)], 1), _c('Table', {
    staticClass: "pb-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.floorMaterials,
      "total-records": _vm.pagination.records_total,
      "number-of-pages": _vm.pagination.total_pages,
      "footer": false,
      "loading": _vm.loading,
      "options": _vm.options,
      "items-per-page": 50
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      },
      "click:row": _vm.edit
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.floorMaterialTypes[item.type]) + " ")])];
      }
    }, {
      key: "item.color_code",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.type === 0 ? _c('v-btn', {
          staticClass: "floor-color",
          attrs: {
            "color": item.color_code,
            "ripple": false,
            "min-height": "65",
            "min-width": "100px"
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.image",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.type === 1 ? _c('v-img', {
          staticClass: "mx-auto floor-img",
          attrs: {
            "src": item.image_url,
            "aspect-ratio": 1,
            "max-width": "100px",
            "max-height": "65px"
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary mr-2",
            "fab": "",
            "x-small": "",
            "to": {
              name: 'FloorMaterialManagementEdit',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }